export enum DepositActionEnum {
    FETCHING_VE_DEPOSIT_DATA = 'fetching ve deposit data',
    FETCH_VE_DEPOSIT_DATA = 'fetch ve deposit data',
    FAIL_VE_DEPOSIT_DATA = 'fail ve deposit data'
}

export type DepositDetailsType = {
    reference_code: string
    deposit_date: string
    amount: number
}

export type DepositStateType = {
    [key: string]: VeDepositStateType
}

export type DepositType = {
    last_deposit: string
    total_deposit: number
    sales_this_season_no_rollover: number
    gap: number
    deposits: DepositDetailsType[]
}

export type VeDepositStateType = {
    veDepositData?: DepositType
    loading: boolean
    error: string
}

type FetchDepositActionType = {
    type:
        | DepositActionEnum.FETCH_VE_DEPOSIT_DATA
        | DepositActionEnum.FETCHING_VE_DEPOSIT_DATA
    payload?: DepositType
    veCode?: string
}

type FailDepositActionType = {
    type: DepositActionEnum.FAIL_VE_DEPOSIT_DATA
    payload?: string
    veCode?: string
}

export type DepositActionType = FetchDepositActionType | FailDepositActionType
