import connectApi from 'api/connectApi'
import {
    constants,
    sendMessageToWorker,
    sendMessageToWorkerWithResponse,
    sortHandler
} from 'helpers'
import { Dispatch } from 'react'
import {
    ClientDetailsPayloadInterface,
    ClientTypes,
    DesiredOutcomes,
    VeHomeEnum,
    InteractionPayloadType,
    TargetListActionType,
    TargetPayloadType
} from 'storeTypes'
import { ErrorResponse } from 'types'

export const fetchTargetListAction =
    ({ veCode }: { veCode: string }) =>
    async (dispatch: Dispatch<TargetListActionType>) => {
        dispatch({ type: VeHomeEnum.FETCHING_TARGET_LIST, veCode })
        try {
            const { data } = await connectApi.get(
                `${constants.endpoints.TARGET_LIST}${veCode}`
            )

            sendMessageToWorker({ 'store target list': data })

            dispatch({
                type: VeHomeEnum.FETCH_TARGET_LIST,
                payload: data as TargetPayloadType[],
                veCode
            })

            if (data.length < 1) {
                dispatch({
                    type: VeHomeEnum.NO_PROCESSED_TARGET_LIST,
                    veCode
                })
            }
        } catch (error: unknown) {
            if (error instanceof ErrorResponse) {
                dispatch({
                    type: VeHomeEnum.FAIL_TARGET_LIST_REQUEST,
                    payload: error.response.data.detail,
                    veCode
                })
            } else {
                dispatch({
                    type: VeHomeEnum.FAIL_TARGET_LIST_REQUEST,
                    payload: 'Un problème est survenu',
                    veCode
                })
            }
        }
    }

interface ProcessTargetListInterface {
    targetList: TargetPayloadType[]
    clients: ClientDetailsPayloadInterface[]
    interactions?: InteractionPayloadType[]
    selectedFilter?: string
    veCode: string
}

export const handleTargetFilter = (
    selectedFilter: string,
    processedTargetList: TargetPayloadType[]
): TargetPayloadType[] => {
    const filterConfig: {
        [key: string]: (targetList: TargetPayloadType) => boolean
    } = {
        [constants.TARGET_LIST_FILTERS.ALL]: () => true,
        [constants.TARGET_LIST_FILTERS.NOT_VISITED]: (
            targetList: TargetPayloadType
        ) => !targetList.isCompleted,
        [constants.TARGET_LIST_FILTERS.VISITED]: (
            targetList: TargetPayloadType
        ) => targetList.isCompleted
    }
    const filterProcessedList = processedTargetList.filter((targetList) =>
        filterConfig[selectedFilter](targetList)
    )
    return filterProcessedList
}

export const processTargetListAction =
    ({
        targetList,
        clients,
        selectedFilter,
        interactions = [],
        veCode
    }: ProcessTargetListInterface) =>
    async (dispatch: Dispatch<TargetListActionType>) => {
        const selectedClientCodes = getTargetListClientCodes(targetList)
        const selectedClients = clients.filter((client) =>
            selectedClientCodes.includes(client.client_code)
        )

        const updatedTargetList = updateTargetList(
            targetList,
            selectedClients,
            interactions
        )

        const sortedTargetList = sortHandler(updatedTargetList, 'ranking')
        sendMessageToWorker({ 'store target list': sortedTargetList })

        const filtered = handleTargetFilter(
            selectedFilter || constants.TARGET_LIST_FILTERS.ALL,
            sortedTargetList
        )
        dispatch({
            type: VeHomeEnum.FETCH_PROCESSED_TARGET_LIST,
            payload: filtered as TargetPayloadType[],
            veCode
        })
    }

const getTargetListClientCodes = (data: Array<{}>) =>
    data.map((item: { [key: string]: string }) => item.client_code)

const updateTargetList = (
    targetList: TargetPayloadType[],
    clients: ClientDetailsPayloadInterface[],
    interactions: InteractionPayloadType[]
) => {
    targetList.map((target: TargetPayloadType) => {
        const clientFound = clients.find(
            (client: ClientDetailsPayloadInterface) =>
                target.client_code === client.client_code
        )
        const interactionFound = interactions.find(
            (interaction: InteractionPayloadType) =>
                target.client_code === interaction.client_code &&
                target.uuid === interaction.target_uuid
        )

        if (clientFound) {
            target.firstname = clientFound.firstname
            target.lastname = clientFound.lastname
            target.fullname = clientFound.fullname
            target.reversed_fullname =
                (clientFound?.lastname ?? '') +
                ' ' +
                (clientFound?.firstname ?? '')
            target.client_status =
                clientFound.client_status || target.client_status
            target.village_id = clientFound.village_id
            target.client_type =
                clientFound.all_enrollment > 0
                    ? ClientTypes.CurrentClient
                    : ClientTypes.PreviousClient
            target.phone = clientFound.phone
            target.isCompleted = !!interactionFound
            target.outcome = interactionFound?.outcome || target.outcome
            target.duplicate = clientFound.duplicate
            target.isFinisher =
                clientFound?.all_enrollment > 0 &&
                (clientFound.balance ?? 0) >= (clientFound.all_enrollment ?? 0)
        }

        if (!Object.values(DesiredOutcomes).includes(target.desired_outcome)) {
            target.desired_outcome = DesiredOutcomes.ENROLLED
        }

        return target
    })

    return targetList
}

export const filterTargetListAction =
    (selectedFilter: string, veCode: string) =>
    async (dispatch: Dispatch<TargetListActionType>) => {
        const { fetchedTargetList } = await sendMessageToWorkerWithResponse({
            'get target list': {}
        })
        dispatch({
            type: VeHomeEnum.FILTER_PROCESSED_TARGET_LIST,
            payload: { selectedFilter, targetList: fetchedTargetList },
            veCode
        })
    }
