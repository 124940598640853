import React, { Dispatch, FC, SetStateAction } from 'react'

export const Referrals: FC<{
    referralsOptions: string[]
    selectedReferrerOption: string
    setSelectedReferrerOption: Dispatch<SetStateAction<string>>
}> = ({
    referralsOptions,
    selectedReferrerOption,
    setSelectedReferrerOption
}) => (
    <div className="referrals">
        {referralsOptions.map((option, index) => (
            <div
                key={index}
                className={`referrals--${
                    selectedReferrerOption === option
                        ? 'selected-option'
                        : 'option'
                }`}
                onClick={() =>
                    !selectedReferrerOption || selectedReferrerOption !== option
                        ? setSelectedReferrerOption(option)
                        : setSelectedReferrerOption('')
                }
                aria-label="referral-option"
            >
                <p>{option}</p>
            </div>
        ))}
    </div>
)
