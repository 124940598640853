import { ContinueButton } from 'components/button/ContinueButton'
import React, { Dispatch, FC, SetStateAction, useState } from 'react'
import './referrals.scss'
import { ButtonStylesEnum } from 'types/buttonStylesEnum'
import { PlusIcon } from 'assets'
import { ClientReferralForm } from './ClientReferralForm'
import { Referrals } from './Referrals'
import { ClientDetailsStateType } from 'components/enrollForm'
import { useGetStorage } from 'hooks'
import { useTranslation } from 'react-i18next'

export const ClientReferrals: FC<{
    selectedReferrerOption: string
    setSelectedReferrerOption: Dispatch<SetStateAction<string>>
    inputText: ClientDetailsStateType
    onChangeHandler: (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => void
    nextStepHandler: () => void
}> = ({
    selectedReferrerOption,
    setSelectedReferrerOption,
    inputText,
    onChangeHandler,
    nextStepHandler
}) => {
    const { t } = useTranslation()
    const referralsOptions = useGetStorage('country_config')?.referrals
    const [onSecondScreen, setShowSecondScreen] = useState(false)

    const shouldShowContinueButton = !onSecondScreen || inputText.referrer

    const shouldShowNextScreen = !(
        selectedReferrerOption ||
        onSecondScreen ||
        referralsOptions.length === 0
    )
    const isContinueButton = !shouldShowNextScreen

    const handleSubmit = () => {
        if (shouldShowNextScreen) {
            setShowSecondScreen(true)
            return
        }

        nextStepHandler()
    }

    const continueBtnProps = {
        style: isContinueButton
            ? ButtonStylesEnum.PRIMARY
            : ButtonStylesEnum.OUTLINE,
        btnText: !isContinueButton ? t('referrals.new_referral_btn') : '',
        icon: !selectedReferrerOption ? <PlusIcon /> : undefined,
        onClick: handleSubmit,
        className: 'enrollment-base--continue-btn referrals--continue-btn'
    }

    return (
        <>
            <p
                className="enrollment--input-label"
                style={{ textAlign: 'center' }}
            >
                {t('referrals.title')}
            </p>
            {!onSecondScreen ? (
                <Referrals
                    referralsOptions={referralsOptions}
                    selectedReferrerOption={selectedReferrerOption}
                    setSelectedReferrerOption={setSelectedReferrerOption}
                />
            ) : (
                <ClientReferralForm
                    inputText={inputText}
                    onChangeHandler={onChangeHandler}
                />
            )}
            {shouldShowContinueButton && (
                <ContinueButton {...continueBtnProps} />
            )}
        </>
    )
}
