import { TextInput } from 'components'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ClientDetailsStateType } from 'components/enrollForm'
import { useValidation } from 'hooks'
import { getConnectUser, validationRules } from 'helpers'
import { ContinueButton } from 'components/button/ContinueButton'
import clientSvgs from 'assets/svg/clientSvgs'

interface TextFieldProps {
    attribute: keyof ClientDetailsStateType
    inputValue: ClientDetailsStateType
    onChangeHandler: (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => void
    onClickHandler?: VoidFunction
    icon?: JSX.Element
    formLabel?: string
}

export const TextField: FC<TextFieldProps> = ({
    attribute,
    inputValue,
    onChangeHandler,
    onClickHandler,
    icon,
    formLabel
}) => {
    const { t } = useTranslation()

    const { country } = getConnectUser()

    const clientInfoValidationRules = validationRules({ country })

    const { errors, validateForm } = useValidation(
        { [attribute]: inputValue[attribute] },
        { [attribute]: clientInfoValidationRules[attribute] }
    )

    const { ClientNameIcon } = clientSvgs

    useEffect(() => {
        if (
            inputValue[attribute] &&
            (inputValue[attribute] as string).length > 0
        ) {
            validateForm()
        }
    }, [inputValue[attribute], country])

    return (
        <>
            <p
                className={`enrollment--input-label form-title ${
                    attribute === 'referrer' && 'referrals-form--input-label'
                }`}
            >
                {icon || <ClientNameIcon />}
                {formLabel || t(`enrollment.${attribute}`)}
            </p>
            <div className="enrollment--input enrollment-button">
                <div>
                    <TextInput
                        id={attribute}
                        name={attribute}
                        className="enrollment--input-field"
                        placeholder={t(`enrollment.${attribute}Placeholder`)}
                        onChange={onChangeHandler}
                        value={inputValue?.[attribute] as string}
                    />
                    {errors[attribute] && (
                        <p className="enrollment--input-error">
                            {errors[attribute]}
                        </p>
                    )}
                </div>
                {inputValue[attribute] &&
                    !errors[attribute] &&
                    onClickHandler && (
                        <ContinueButton onClick={onClickHandler} />
                    )}
            </div>
        </>
    )
}
