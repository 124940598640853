import React, { useEffect, useRef, useState } from 'react'
import { Container, Row, Col, Spinner, TimedAlert } from 'components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/configStore'
import { helpIcon, calcIcon, logoutIcon, moneyIcon } from '../../assets'
import './profile.css'
import { useBindDispatch, useLogout } from 'hooks'
import { useNavigate } from 'react-router-dom'
import { getConnectUser, constants } from 'helpers'
import { CSSTransition } from 'react-transition-group'
import { VeMetrics } from './VeMetrics'

export const Profile = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const {
        loading,
        userData,
        error: userDataError
    } = useSelector(({ home }: RootState) => home)
    const [animationList, setAnimationList] = useState(false)
    const [animate, setAnimate] = useState(false)
    const nodeRef = useRef(null)
    const { logout } = useLogout()
    const { veHomeHandler, openVillagePageHandler } = useBindDispatch()
    const connectUser = getConnectUser()

    useEffect(() => {
        setAnimate(true)
        if (userData.length < 1) {
            veHomeHandler({
                veCode: connectUser.username,
                country: connectUser.country
            })
        }
    }, [])

    const cardClickHandler = (filterOptions: string[], clientTab: number) => {
        const hasMultipleVillages = connectUser?.veVillages.length > 1

        openVillagePageHandler({
            filterOptions,
            clientTab,
            villageId: hasMultipleVillages
                ? ''
                : connectUser?.veVillages[0].sf_id
        })

        navigate('/village')
    }

    const { name } = userData[0] || { name: '' }

    const topSection = (
        <>
            <div className="profile-hearder-container">
                <p className="profile-title">{t('profile.title')}</p>
                <button
                    className="profile-logout-btn"
                    onClick={() => {
                        logout()
                    }}
                    aria-label="logout"
                >
                    {logoutIcon({})}
                </button>
            </div>
            <div className="profile-user-info">
                <p className="profile-name">{name}</p>
                <div className="profile-buttons-container">
                    <button
                        className="profile-button"
                        onClick={() => {
                            navigate('/calcapp')
                        }}
                        aria-label={t('profile.calculator')}
                    >
                        {calcIcon({})}
                        <p>{t('profile.calculator')}</p>
                    </button>
                    <button
                        className="profile-button"
                        aria-label={t('profile.cards')}
                        style={{ color: '#b6b5b5' }}
                    >
                        {moneyIcon({})}
                        <p>{t('profile.cards')}</p>
                    </button>
                    <button
                        className="profile-button"
                        aria-label={t('profile.help')}
                    >
                        {helpIcon({})}
                        <p>{t('profile.help')}</p>
                    </button>
                </div>
            </div>
        </>
    )

    const profilePage = loading ? (
        <Spinner size={'90'} pageSpinner={true} fullscreen={true} />
    ) : userDataError ? (
        <TimedAlert type="floating" status="error">
            {userDataError}
        </TimedAlert>
    ) : (
        <CSSTransition
            unmountOnExit
            timeout={constants.ANIMATION_TIMEOUT}
            in={animate}
            classNames="generic"
            appear
            onEnter={() => setAnimationList(true)}
            nodeRef={nodeRef}
        >
            <div className="page-wrapper" ref={nodeRef}>
                <Container className="profile-main">
                    <Row>
                        <Col md={12}>
                            <Col md={12}>{topSection}</Col>
                            <VeMetrics
                                animationList={animationList}
                                ve={userData[0]}
                                connectUser={connectUser}
                                canViewDeposits={true}
                                canViewDepositGap={true}
                                cardClickHandler={cardClickHandler}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </CSSTransition>
    )
    return profilePage
}
