import {
    DepositActionEnum,
    DepositActionType,
    DepositStateType
} from 'storeTypes'

const initialState: DepositStateType = {}

export const veDepositReducer = (
    state = initialState,
    action: DepositActionType
): DepositStateType => {
    switch (action.type) {
        case DepositActionEnum.FETCH_VE_DEPOSIT_DATA:
            return {
                ...state,
                [action.veCode || '']: {
                    veDepositData: action.payload,
                    loading: false,
                    error: ''
                }
            }
        case DepositActionEnum.FETCHING_VE_DEPOSIT_DATA:
            return {
                ...state,
                [action.veCode || '']: {
                    loading: true,
                    error: '',
                    veDepositData: undefined
                }
            }
        case DepositActionEnum.FAIL_VE_DEPOSIT_DATA:
            return {
                ...state,
                [action.veCode || '']: {
                    loading: false,
                    veDepositData: undefined,
                    error: action.payload || ''
                }
            }
        default:
            return state
    }
}
