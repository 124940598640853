import React from 'react'
import { Outlet, Route } from 'react-router-dom'
import { FcHome } from 'fc/pages'
import { FcProfile } from 'fc/pages/profile/FcProfile'

const ConnectRoutes = () => {
    return (
        <Route path="/fc" element={<Outlet />}>
            <Route path="home" element={<FcHome />} />
            <Route path="profile" element={<FcProfile />} />
        </Route>
    )
}

export default ConnectRoutes
