import { Button, Container, Modal, NumberInput, TimedAlert } from 'components'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import QrScanner from 'qr-scanner'
import { useValidation } from 'hooks'
import { constants } from 'helpers/constants'
import './cardPayment.scss'
import { amountFormatter } from 'helpers'

type CardPaymentPageProps = {
    voucherCode?: string
    voucherAmount?: number
    paymentSource?: string
    continueButtonText?: string
    onPaymentConfirmation: (
        voucherCode: string,
        voucherAmount: number,
        source: string
    ) => void
}

export const CardPaymentPage: FC<CardPaymentPageProps> = ({
    voucherCode: initialCode,
    voucherAmount: initialAmount = 0,
    continueButtonText,
    paymentSource,
    onPaymentConfirmation
}) => {
    const { t } = useTranslation()
    const videoRef = useRef<HTMLVideoElement | null>(null)
    const QRScannerRef = useRef<QrScanner>()
    const [videoElm, setVideoElm] = useState<HTMLVideoElement>()
    const [qrScannerError, setQrScannerError] = useState('')
    const [validationError, setValidationError] = useState('')
    const [showVoucherCodeInput, setShowVoucherCodeInput] = useState(false)
    const [voucherCode, setVoucherCode] = useState<string | undefined>(
        initialCode
    )
    const [qrData, setQrData] = useState('')
    const [voucherAmount, setVoucherAmount] = useState<number>(initialAmount)
    const [showVoucherAmountSelection, setShowVoucherAmountSelection] =
        useState(false)

    const cleanVoucherCode = (voucherCode: string) =>
        voucherCode.indexOf('__') < 0
            ? voucherCode
            : voucherCode.substring(0, voucherCode.indexOf('__'))

    const { errors, validateForm, resetError } = useValidation(
        { cardCode: cleanVoucherCode(voucherCode || '') },
        {
            cardCode: [
                (value: string | boolean) => {
                    if (
                        !value ||
                        (typeof value === 'string' &&
                            value.length !== constants.VOUCHER_CODE_LENGTH)
                    ) {
                        return t('cardPayment.invalidCardNumber')
                    }
                    return undefined
                }
            ]
        }
    )
    let stream: MediaStream | null

    const getQR = async () => {
        try {
            setQrScannerError('')
            if (!stream) {
                // open user camera
                stream = await navigator.mediaDevices.getUserMedia({
                    video: {
                        facingMode: { ideal: 'environment' }
                    },
                    audio: false
                })
                if (!videoRef.current) return
                videoRef.current.srcObject = stream
                await videoRef.current.play()
                setVideoElm(videoRef.current)
            }
        } catch (error: DOMException | unknown) {
            if (
                error instanceof DOMException &&
                error.name === 'NotAllowedError'
            ) {
                setQrScannerError(t('qrCodePermissionError'))
            } else {
                console.error('QR Code scanner error: ', error)
            }
        }
    }

    const isQrCodeUrlValid = (url: URL) => {
        const baseCardUrl = process.env.REACT_APP_QR_CODE_BASE_URL
        const qrCodeUrl = `${url.protocol}//${url.host}`

        const pathName = url.pathname.split('/')
        return baseCardUrl === qrCodeUrl && pathName[1] === 'card'
    }

    useEffect(() => {
        resetError()
        validateForm()

        if (voucherCode && !navigator.onLine && qrData) {
            setShowVoucherCodeInput(true)
            return
        }

        if (voucherCode && qrData) {
            closeCamera()
            onPaymentConfirmation(
                cleanVoucherCode(voucherCode) as string,
                voucherAmount,
                constants.PAYMENT_SOURCES.QR_CODE
            )
        }
    }, [voucherCode])

    const closeCamera = () => {
        if (QRScannerRef.current) {
            QRScannerRef.current.stop()
            QRScannerRef.current.destroy()
            QRScannerRef.current = undefined
        }
        if (stream) {
            stream.getTracks()[0].stop()
            stream.getTracks()[0].enabled = false
            stream = null
        }
    }

    useEffect(() => {
        setQrScannerError('')
        getQR()
        if (videoElm) {
            try {
                // initiate qr scanner
                QRScannerRef.current = new QrScanner(
                    videoElm,
                    ({ data }) => {
                        try {
                            if (data) {
                                // parse url
                                const url = new URL(data)
                                if (!isQrCodeUrlValid(url)) {
                                    setValidationError(t('invalidQrCode'))
                                } else {
                                    setVoucherAmount(
                                        parseInt(
                                            url.searchParams.get('a') || '0'
                                        )
                                    )
                                    setVoucherCode(
                                        `${url.searchParams.get(
                                            'n'
                                        )}__${Date.now()}` || undefined
                                    )
                                    setQrData(data)
                                }
                            }
                        } catch (error) {
                            setQrScannerError(t('qrCodeError'))
                        }
                    },
                    {
                        highlightScanRegion: true,
                        returnDetailedScanResult: true,
                        highlightCodeOutline: true
                    }
                )
                // set qr scan listener
                QRScannerRef.current.start()
            } catch (error) {
                setQrScannerError(t('qrCodeError'))
            }
        }
    }, [videoElm])

    useEffect(() => {
        return () => {
            closeCamera()
        }
    }, [])

    const selectManualTyping = () => {
        validateForm()
        setShowVoucherCodeInput(true)
    }

    const CARD_AMOUNT_OPTION = [1000, 2000, 5000, 10000, 25000]

    const cardAmountOption = (amount: number) => (
        <p
            key={amount}
            onClick={() => setVoucherAmount(amount)}
            className={
                voucherAmount === amount
                    ? 'card-amount-option card-amount-selected'
                    : 'card-amount-option'
            }
        >
            {amountFormatter(amount)}
        </p>
    )

    return (
        <Container sm={12}>
            <Container>
                <div className="product--selection-header">
                    <h2 className="product--selection-title">
                        {t('cardPayment.scanACard')}
                    </h2>
                </div>
            </Container>
            <Container className="card-payment-content">
                <video
                    ref={videoRef}
                    className="card-qr--video"
                    data-testid="qr-camera"
                />
                <p className="scanner-label">
                    {validationError && (
                        <span className="invalid-qr-error">
                            {validationError}
                        </span>
                    )}
                </p>
                <Button
                    className="manual-typing-button"
                    style="secondary"
                    onClick={() => selectManualTyping()}
                >
                    {t('cardPayment.cardManualTyping')}
                </Button>
                <Modal
                    closeClickHandler={() => setShowVoucherCodeInput(false)}
                    position="bottom"
                    showModal={showVoucherCodeInput}
                    hideButtons={true}
                    className="client-details--modal"
                    showModalSlideHandle
                    testId="voucher-input-modal"
                >
                    <>
                        <h2 className="voucher-code-title">
                            {t('cardPayment.voucherNumber')}
                        </h2>
                        <div>
                            <NumberInput
                                length={constants.VOUCHER_CODE_LENGTH}
                                onChangeNumberHandler={(code: string) =>
                                    setVoucherCode(code)
                                }
                                className={'voucher--input-field'}
                                initialValue={cleanVoucherCode(
                                    voucherCode || ''
                                )}
                                disabled={
                                    paymentSource ===
                                    constants.PAYMENT_SOURCES.QR_CODE
                                }
                            />
                            {errors.cardCode && (
                                <p className="enrollment--input-error">
                                    {errors.cardCode}
                                </p>
                            )}
                        </div>
                    </>
                    {showVoucherAmountSelection && (
                        <div className="card-amounts-container">
                            <h2 className="voucher-code-title">
                                {t('card.cardAmount')}
                            </h2>
                            <div className="card-amount-options-list">
                                {CARD_AMOUNT_OPTION.map(cardAmountOption)}
                            </div>
                        </div>
                    )}
                    {(!errors.cardCode && !showVoucherAmountSelection) ||
                    (showVoucherAmountSelection && voucherAmount) ? (
                        <Button
                            size="large"
                            style="primary"
                            className="client-details--bottom-button"
                            onClick={() => {
                                if (
                                    !showVoucherAmountSelection &&
                                    voucherAmount === 0
                                ) {
                                    setShowVoucherAmountSelection(true)
                                    return
                                }
                                onPaymentConfirmation(
                                    cleanVoucherCode(
                                        voucherCode || ''
                                    ) as string,
                                    voucherAmount,
                                    paymentSource ||
                                        constants.PAYMENT_SOURCES.MANUAL
                                )
                            }}
                            disable={!!errors.cardCode}
                        >
                            {continueButtonText || t('continue')}
                        </Button>
                    ) : (
                        <></>
                    )}
                </Modal>
            </Container>
            {qrScannerError && (
                <TimedAlert type="floating" status="error">
                    {qrScannerError}
                </TimedAlert>
            )}
        </Container>
    )
}
