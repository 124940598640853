import { ClientDetailsStateType } from 'components/enrollForm'
import { TextField } from 'components/enrollForm/TextField'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const ClientReferralForm: FC<{
    inputText: ClientDetailsStateType
    onChangeHandler: (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => void
}> = ({ inputText, onChangeHandler }) => {
    const { t } = useTranslation()
    return (
        <>
            <TextField
                key={'referrer'}
                attribute="referrer"
                inputValue={inputText}
                onChangeHandler={onChangeHandler}
                icon={<></>}
                formLabel={t('referrals.form.new_grouping_label')}
            />
        </>
    )
}
