import connectApi from '../api/connectApi'
import { constants } from '../helpers'
import { Dispatch } from 'react'
import axios from 'axios'
import { DepositActionType, DepositActionEnum } from 'storeTypes'

export const veDepositsAction =
    ({ veCode }: { veCode: string }) =>
    async (dispatch: Dispatch<DepositActionType>) => {
        const { VE_DEPOSIT } = constants.endpoints
        dispatch({ type: DepositActionEnum.FETCHING_VE_DEPOSIT_DATA, veCode })
        try {
            // get ves data
            const { data } = await connectApi.get(`${VE_DEPOSIT}${veCode}/`)
            dispatch({
                type: DepositActionEnum.FETCH_VE_DEPOSIT_DATA,
                payload: data,
                veCode
            })
        } catch (error: unknown) {
            const errorMessage = axios.isAxiosError(error)
                ? error.message
                : 'Failed to fetch ve deposits'
            dispatch({
                type: DepositActionEnum.FAIL_VE_DEPOSIT_DATA,
                payload: errorMessage,
                veCode
            })
        }
    }
